const env = process.env.ENV || 'development';
// console.log(`ENV: ${env}`);

const config = {
  development: {
    apiPath: 'http://127.0.0.1:5001/hire-humanly-dev/us-central1/',
    // apiPath: 'https://us-central1-hire-humanly-dev.cloudfunctions.net/', // for use with API pointing to deployed dev database
    basePath: 'http://localhost:3001/',
    baseHost: 'localhost:3001',
    env,
    chattrbotJs: 'http://localhost:1701/chat.bundle.js',
    newApiPath: 'http://127.0.0.1:3333',
    feedApiPath: 'http://127.0.0.1:3333',
    // apiPath: 'https://us-central1-hire-humanly.cloudfunctions.net/',
    // basePath: 'https://jobs.chattr.ai/',
    // baseHost: 'chattr.jobs',
    // env,
    // appInsightsKey: 'aa7d4107-cf09-4a5d-96b2-e18421f386ec',
    // chattrbotJs: `https://bot.chattr.ai/chat.js`,
    // newApiPath: 'https://api.chattr.ai',
  },
  // USE THE CONFIG BELOW IF TESTING LOCALLY WITH BROWSERSTACK
  // development: {
  //   apiPath: "https://us-central1-hire-humanly-dev.cloudfunctions.net/",
  //   basePath: "http://bs-local.com:3001/",
  //   baseHost: "bs-local.com:3001",
  //   env: env,
  //   appInsightsKey: "36d3a21d-ee7d-4b39-9ea3-4f658564f9c1",
  //   chattrbotJs: "http://bs-local.com:1701/chat.js",
  //   newApiPath: "http://bs-local.com:3333",
  // },
  staging: {
    apiPath: 'https://us-central1-hire-humanly-dev.cloudfunctions.net/',
    basePath: 'https://jobs-dev.chattr.ai/',
    baseHost: 'dev.chattr.jobs',
    env,
    chattrbotJs: `https://bot-dev.chattr.ai/chat.min.js`,
    newApiPath: 'https://api-dev.chattr.ai',
    feedApiPath: 'https://api-dev.chattr.ai',
  },
  'staging-uat': {
    apiPath: 'https://us-central1-chattr-uat.cloudfunctions.net/',
    basePath: 'https://jobs-uat.chattr.ai/',
    baseHost: 'uat.chattr.jobs',
    env,
    chattrbotJs: `https://bot-uat.chattr.ai/chat.min.js`,
    newApiPath: 'https://api-uat.chattr.ai',
    feedApiPath: 'https://api-uat.chattr.ai',
  },
  prodstaging: {
    apiPath: 'https://us-central1-hire-humanly.cloudfunctions.net/',
    basePath: 'https://jobs-staging.chattr.ai/',
    baseHost: 'chattr.jobs',
    env,
    chattrbotJs: `https://bot-staging.chattr.ai/chat.min.js`,
    newApiPath: 'https://api-staging.chattr.ai',
    feedApiPath: 'https://api-staging.chattr.ai',
  },
  production: {
    apiPath: 'https://us-central1-hire-humanly.cloudfunctions.net/',
    basePath: 'https://jobs.chattr.ai/',
    baseHost: 'chattr.jobs',
    env,
    chattrbotJs: `https://bot.chattr.ai/chat.min.js`,
    newApiPath: 'https://api.chattr.ai',
    feedApiPath: 'https://chattr-api-prod.azurewebsites.net',
  },
}[env];

// console.log(`Config in Config: ${JSON.stringify(config)}`);
module.exports = config;
